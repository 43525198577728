import axios from "axios";
import axiosRetry from "axios-retry";
import { urlBuilder } from "./server/urlBuilder";

const defaultRetryCondition = (error) => error.code !== "ECONNABORTED";

function isHeaderExcluded(headerLoggingExcludes, header) {
  for (const excludedHeader of headerLoggingExcludes) {
    if (excludedHeader.toLowerCase() === header.toLowerCase()) {
      return true;
    }
  }
}

function filterHeaders(headerLoggingExcludes) {
  return (headers) => {
    if (headerLoggingExcludes) {
      return Object.keys(headers).reduce((acc, headerName) => {
        if (!isHeaderExcluded(headerLoggingExcludes, headerName)) {
          acc[headerName] = headers[headerName];
        }
        return acc;
      }, {});
    }
    return headers;
  };
}

const getServerRequestInterceptor =
  ({ logger, filterHeadersInstance }) =>
  (config) => {
    const serverUuid = config.asosConfig && config.asosConfig.serverUuid;
    config.timing = {
      start: Date.now(),
    };

    logger.http("Request", {
      url: urlBuilder(config),
      method: config.method,
      headers: filterHeadersInstance(config.headers),
      serverUuid,
    });
    return config;
  };

function getServerResponseInterceptor({
  serverRequestLogger,
  logger,
  filterHeadersInstance,
}) {
  const logRequest = (response, headers, params) => {
    const { start } = response.config.timing;
    const timeTaken = Date.now() - start;

    if (serverRequestLogger) {
      serverRequestLogger({
        ...response.config.asosConfig,
        "request.url": urlBuilder(response.config),
        method: response.config.method,
        timeTaken,
        ...params,
      });
    }
    logger.http("Response", {
      ...response.config.asosConfig,
      ...params,
      url: urlBuilder(response.config),
      method: response.config.method,
      headers: filterHeadersInstance(headers),
      timeTaken,
    });
  };

  const handleResponse = (response) => {
    logRequest(response, response.headers, {
      "response.status": response.status,
      httpResponseMessage: response.statusText,
    });
    return response;
  };

  const handleResponseFailure = (error) => {
    if (!axios.isCancel(error)) {
      let response = error;
      let headers = error.config.headers;
      if (error.response) {
        response = error.response;
        headers = error.response.headers;
      }
      logRequest(response, headers, {
        "response.status": error.response ? error.response.status : null,
        errorCode: error.code,
        errorMessage: error.toString(),
      });
    }
    return Promise.reject(error);
  };

  return [handleResponse, handleResponseFailure];
}

const getClientRequestInterceptor = () => (request) => request;

const getClientResponseInterceptor = () => [
  (response) => response,
  (error) => Promise.reject(error),
];

const getRequestInterceptor = ({ logger, filterHeadersInstance }) =>
  SERVER
    ? getServerRequestInterceptor({ logger, filterHeadersInstance })
    : getClientRequestInterceptor();

const getResponseInterceptor = ({
  serverRequestLogger,
  logger,
  filterHeadersInstance,
}) =>
  SERVER
    ? getServerResponseInterceptor({
        serverRequestLogger,
        logger,
        filterHeadersInstance,
      })
    : getClientResponseInterceptor();

export default function enhanceAxios({
  serverRequestLogger,
  httpsAgent,
  retries,
  retryDelay,
  retryCondition = defaultRetryCondition,
  shouldResetTimeout,
  logger,
  headerLoggingExcludes,
} = {}) {
  const axiosInstance = axios.create({ httpsAgent });
  const filterHeadersInstance = filterHeaders(headerLoggingExcludes);

  axiosInstance.interceptors.request.use(
    getRequestInterceptor({ logger, filterHeadersInstance })
  );
  axiosInstance.interceptors.response.use(
    ...getResponseInterceptor({
      serverRequestLogger,
      logger,
      filterHeadersInstance,
    })
  );

  axiosRetry(axiosInstance, {
    retries,
    retryDelay,
    retryCondition,
    shouldResetTimeout,
  });

  return axiosInstance;
}
